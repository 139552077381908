import {Icon} from 'src/Icon'
import {VStack} from './Stack'
import styles from './InnerPageHeader.module.css'
import {Text} from '@components/Text'
import {ProBadge} from 'src/ProBadge'
import {motion} from 'framer-motion'

const InnerPageHeader = ({icon, showProBadge, title, subtitle, beta}) => {
  return (
    <motion.div
      className={styles.innerPageHeader}
      initial={{opacity: 0}}
      exit={{opacity: 0}}
      animate={{opacity: 1}}
    >
      <VStack>
        {icon ? (
          <Icon
            size="32"
            marginRight="0"
            name={icon}
            color="var(--clr-brand)"
          ></Icon>
        ) : null}

        {showProBadge ? <ProBadge height="24" /> : null}

        <div className="relative">
          <Text
            size={4}
            weight="600"
            mono={false}
            tag="h1"
            appearance="primary"
          >
            {title}
          </Text>
          {beta ? (
            <div
              className="pill "
              style={{position: 'absolute', left: '100%', top: '-0.5rem'}}
            >
              Beta
            </div>
          ) : null}
        </div>
        <div style={{maxWidth: '72ch'}}>
          <Text
            size={1}
            weight="500"
            mono={false}
            tag="p"
            letterSpacing="0.3px"
            appearance="secondary"
            lineHeight="1.5"
            align="center"
          >
            {subtitle}
          </Text>
        </div>
      </VStack>
    </motion.div>
  )
}

export {InnerPageHeader}
