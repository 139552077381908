import {sendEvent} from './analytics'

import {
  sendSignInLinkToEmail,
  GithubAuthProvider,
  TwitterAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from 'firebase/auth'
import {auth} from './firebase-init'

function loginWithEmail(email) {
  var actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be whitelisted in the Firebase Console.
    url: `${window.location.origin}/login`,
    // This must be true.
    handleCodeInApp: true,
  }

  return sendSignInLinkToEmail(auth, email, actionCodeSettings)
    .then(() => {
      // The link was successfully sent. Inform the user.
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      window.localStorage.setItem('emailForSignIn', email)
    })
    .catch(function (error) {
      console.error(error.code, error)
    })
}
export function login({providerName, email}) {
  if (email) {
    return loginWithEmail(email)
  }
  let provider
  if (providerName === 'github') {
    provider = new GithubAuthProvider()
  } else if (providerName === 'twitter') {
    provider = new TwitterAuthProvider()
  } else if (providerName === 'google') {
    provider = new GoogleAuthProvider()
    provider.addScope('https://www.googleapis.com/auth/userinfo.profile')
  }

  return signInWithPopup(auth, provider)
    .then(function () {
      sendEvent({name: 'loggedIn'})
      window.localStorage.setItem('lastAuthProvider', providerName)
    })
    .catch(function (error) {
      console.log(error)
      if (error.code === 'auth/account-exists-with-different-credential') {
        alert(
          'You have already signed up with the same email using different social login. Eg. If it was Twitter previously, use Twitter now too.'
        )
      } else if (error.code === 'auth/web-storage-unsupported') {
        alert(
          'Please allow 3rd party cookies in your browser for login. Or try a different browser.'
        )
      }
    })
}

export function attemptLoginFromLink() {
  if (isSignInWithEmailLink(auth, window.location.href)) {
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    var email = window.localStorage.getItem('emailForSignIn')
    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      email = window.prompt('Please provide your email for confirmation')
    }
    if (!email) {
      return Promise.reject()
    }
    // The client SDK will parse the code from the link for you.
    return signInWithEmailLink(auth, email, window.location.href)
      .then(function (result) {
        // Clear email from storage.
        window.localStorage.removeItem('emailForSignIn')
        window.localStorage.setItem('lastAuthProvider', 'email')

        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
      })
      .catch(function (error) {
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
      })
  }
  return Promise.reject()
}
