import Head from 'next/head'
import {useRouter} from 'next/router'
import Link from 'next/link'
import {useState, useEffect, useContext} from 'react'
import {login, attemptLoginFromLink} from 'src/auth'
import {SocialLoginButton} from '@components/SocialLoginButton'
import {Button} from 'src/Button'
import {UserContext} from 'src/contexts'
import {Loader} from 'src/Loader'
import {Checkbox} from 'src/Common.jsx'
import {Text} from '@components/Text'
import {Panel} from '@components/Panel'
import {HStack, VStack} from '@components/Stack'
import {InnerPageHeader} from '@components/InnerPageHeader'

const LoginPage = () => {
  const router = useRouter()
  const user = useContext(UserContext)

  const [lastAuthProvider, setLastAuthProvider] = useState()
  useEffect(() => {
    setLastAuthProvider(window.localStorage.getItem('lastAuthProvider'))
  }, [])
  const [shouldSubscribeToEmail, setShouldSubscribeToEmail] = useState(false)

  useEffect(() => {
    if (user) {
      // If we got a user and this path is #login, try getting the `beforeLoginKey`
      // from localstorage to see if we need to redirect there.
      const previousPath = window.localStorage.getItem('beforeLoginLocation')

      if (previousPath) {
        window.localStorage.removeItem('beforeLoginLocation')
        // Replacing so that user can't come back to /#login. thats useless.
        router.replace(previousPath)
      } else {
        router.replace('/')
      }
    }
  }, [user])

  function saveSubscriptionPreference() {
    if (shouldSubscribeToEmail) {
      window.localStorage.setItem('pendingNewsletterEmailSubscription', true)
    }
  }
  function loginBtnClickHandler(providerName) {
    saveSubscriptionPreference()
    login({providerName})
  }

  const [isEmailSent, setIsEmailSent] = useState()
  const [isEmailBeingSent, setIsEmailBeingSent] = useState()
  const [email, setEmail] = useState('')
  function emailLoginHandler(event) {
    event.preventDefault()
    if (!email) {
      return
    }
    setIsEmailBeingSent(true)
    saveSubscriptionPreference()
    login({email}).then(() => {
      setIsEmailBeingSent(false)
      setIsEmailSent(true)
    })
  }

  const isDirectLogin = router.asPath.match(/apiKey=/)

  // Login if the page is opened with signIn link from email
  useEffect(() => {
    var searchParams = new URLSearchParams(window.location.search)
    if (searchParams.get('apiKey')) {
      attemptLoginFromLink()
    }
  }, [])

  return (
    <>
      <Head>
        <title>Login | CSSBattle</title>
      </Head>

      <div className="login-background"></div>

      <InnerPageHeader title="Sign up / Sign in" />
      <div style={{marginTop: '1rem'}}>
        <HStack justify="center">
          <div
            style={{
              opacity: isDirectLogin ? 0.2 : 1,
              filter: isDirectLogin ? 'blur(3px)' : null,
              pointerEvents: isDirectLogin ? 'none' : 'initial',
            }}
          >
            <Panel>
              <div className="login-form-container">
                {!lastAuthProvider ? (
                  <div>
                    <Checkbox
                      label="Subscribe to CSSBattle's monthly newsletter"
                      checked={shouldSubscribeToEmail}
                      onChange={(e) =>
                        setShouldSubscribeToEmail(e.target.checked)
                      }
                    />
                    <hr style={{marginTop: '-1rem'}} />
                  </div>
                ) : null}
                <form onSubmit={emailLoginHandler}>
                  <div
                    className="input-container"
                    style={{marginBottom: '1rem'}}
                  >
                    <div className="input-with-button">
                      <input
                        type="email"
                        id="email"
                        onInput={(e) => setEmail(e.target.value)}
                        placeholder="example@domain.com"
                      />
                      <Button type="submit" isLoading={isEmailBeingSent}>
                        Sign in with Email{' '}
                        {lastAuthProvider === 'email' ? ' (last used)' : ''}
                      </Button>
                    </div>
                    <p className="input-hint">
                      This is a password-less login, so you don't need a
                      password.
                    </p>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  />
                </form>
                {isEmailSent && (
                  <div
                    style={{
                      padding: '0.5rem 1rem',
                      background: '#276827',
                      borderRadius: '5px',
                      marginBottom: '2rem',
                    }}
                  >
                    <Text mono={false} weight={500} align="center" size={1}>
                      A password-less login link has been sent to your email (
                      {email})
                    </Text>
                  </div>
                )}
                <div style={{marginTop: '1rem'}}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '2rem',
                    }}
                  >
                    <hr style={{margin: '0 1rem 0 10rem', flex: 1}} />
                    <span style={{margin: 0}}>OR</span>
                    <hr style={{margin: '0 10rem 0 1rem', flex: 1}} />
                  </div>
                  <p className="login-buttons-container">
                    <SocialLoginButton
                      type="github"
                      id="login"
                      lastAuthProvider={lastAuthProvider}
                      onClick={() => loginBtnClickHandler('github')}
                    >
                      Github
                    </SocialLoginButton>
                    <SocialLoginButton
                      type="twitter"
                      lastAuthProvider={lastAuthProvider}
                      onClick={() => loginBtnClickHandler('twitter')}
                    >
                      Twitter
                    </SocialLoginButton>
                    <SocialLoginButton
                      type="google"
                      lastAuthProvider={lastAuthProvider}
                      onClick={() => loginBtnClickHandler('google')}
                    >
                      Google
                    </SocialLoginButton>
                  </p>
                </div>
              </div>
            </Panel>

            <p className="para  login-disclaimer">
              By using CSSBattle, you agree to our{' '}
              <Link className="link" href="/privacy-and-terms">
                Privacy Policy and Terms of Service
              </Link>
            </p>
          </div>

          {isDirectLogin ? (
            <div
              style={{
                position: 'absolute',
                top: '40%',
              }}
            >
              <Panel>
                <HStack align="center">
                  Logging you in...
                  <Loader />
                </HStack>
              </Panel>
            </div>
          ) : null}
        </HStack>
      </div>
    </>
  )
}

export default LoginPage
